@media (min-width: 1300px) and (max-width: 1440px){
	.truba_pic{
		margin-left: 150px;
	}
}
@media (min-width: 1300px){
	.section_main{
		min-height: 520px;
		height: calc(100vh - 100px);
	}
	.truba_pic{
		min-height: 390px;
		height: 63vh;
	}
}
@media (min-width: 1200px){
	.header_items{
		display: none;
	}
}
@media(max-width: 1199px){
	.section_services{
		padding: 50px 0 60px;
	}
	.service{
		height: 390px;
		margin-bottom: 30px;
		&_img_wrap{
			height: 60%;
		}
		&_title{
			height: 40%;
		}
	}
	.section_main{
		.title{
			margin: 0 0 10px;
			font-size: 36px;
			line-height: 1.17;
			letter-spacing: -0.375px;
		}
		.text{
			margin-bottom: 23px;
			font-size: 16px;
			line-height: 1.5;
		}
		
		&_top{
			padding: 33px 0 41px;
		}
		&_bottom{
			position: static !important;
			z-index: 1 !important;
		}
	}
	.truba_pic{
		max-height: 350px;
	}
	.menu_slider{
		margin: 0 -15px;
		&_item{
			padding: 40px 26px;
		}
	}
	.mobile-menu{
		display: flex;
	}
	body{
		padding-top: 77px;
	}
	.header{
		position: fixed;
		top: 0;
		height: auto;
		padding: 23px 0;
		&_contacts{
			display: none;
		}
	}
	.logo{
		svg{
			width: 170px;
			height: 31.45px;
		}
	}
	.footer .col-md-6:first-child{
		padding: 60px 0 60px 15px;
	}
	.section_advantages{
		padding: 40px 0 260px;
		&_worker{
			width: 125%;
			object-position: 200px bottom;
			object-fit: contain;
		}
	}
	.section_about{
		padding: 50px 0 70px;
		.section_title{
			margin-bottom: 39px;
		}
	}
	.section_form{
		padding: 46px 0 20px;
		&_phone{
			margin-top: -90px;
		}
	}
	.section_docs{
		padding: 50px 0 33px;
		.section_title{
			margin-bottom: 30px;
		}
	}
	.docs_wrap{
		flex-wrap: nowrap;
		overflow: auto;
		justify-content: flex-start;
	}
	.doc{
		margin-right: 30px;
		flex: 1 0 auto;
	}
	.section_reviews{
		padding: 70px 0;
	}
	.reviews_slider{
		&_slide{
			height: 430px;
			padding: 35px 0 35px 55px;
		}
		&_doc{
			width: 193px;
			height: 270px;
			margin-right: -96px;
		}
		&_controls{
			left: 250px;
			bottom: 40px;
		}
	}
}
@media (max-width: 991px){
	.hor_form{
		.form_wrap{
			max-width: 270px;
			form{
				flex-direction: column;
			}
			.input_wrap{
				width: 100%;
				margin: 0 0 10px 0;
			}
			input[type="submit"]{
				width: 100%;
			}
		}
		.data{
			font-size: 14px;
		}
	}
	.section_form{
		&_phone{
		    max-width: 290px;
		    display: block !important;
		    margin: -80px auto 0;
		}
	}
	.section_reviews{
		padding: 50px 0 0;
		.section_title{
			margin-bottom: 36px;
			text-align: center;
		}
	}
	.reviews_slider{
		&_controls{
			display: flex;
			justify-content: space-between;
			padding: 0 25px;
			left: 0;
			right: 0;
			bottom: 20px;
			.slick-dots{
				padding: 0 0 2px;
			}
		}
		&_info{
			max-width: none;
			&_top{
				margin-bottom: 30px;
			}
		}
		&_slide{
			padding: 30px 20px 128px;
			flex-direction: column;
			height: auto;
			max-width: none;
		}
		&_doc{
			margin: 15px auto 0;
		}
		&_text{
			font-size: 16px;
		}
		&::before{
			max-width: none;
		}
	}
	.section_title{
		font-size: 30px;
		line-height: 1.2;
		letter-spacing: -0.416667px;
	}
	.section_main{
		.bottom{
			padding-top: 40px;
			.title{
				font-size: 46px;
				line-height: 1;
				letter-spacing: -0.8px;
				padding-bottom: 20px;
			}
			.warning{
				font-size: 12px;
				line-height: 1;
				padding: 90px 0 30px;
			}
		}
	}
	.footer{
		.bottom{
			padding: 20px 0 28px;
			.text{
				margin-top: 12px;
				text-align: center;
				&.made_by{
					float: none;
					margin-top: 12px;
				}
			}
		}
	}
	.section_advantages{
		padding-bottom: 540px;
		.advantage{
			margin-bottom: 30px;
		}
		&_worker{
			width: 100%;
			object-position: right bottom;
		}
	}
}
@media (max-width: 767px){
	.section_main{
		.title{
			text-align: center;
		}
		.text{
			text-align: center;
			br{
				display: none;
			}
		}
		.button{
			margin: 0 auto;
		}
		&_bg_logo{
			display: none;
		}
	}
	.hor_form .form_wrap{
		margin: 0 auto;
	}
	.truba_col{
		display: none;
	}
	.footer{
		.map{
			width: auto;
			height: 370px;
		}
		.col-md-6:first-child{
			padding: 50px 15px 30px !important;
		}
		.col-md-6:last-child{
			padding: 0;
		}
		.secton_title{
			text-align: center;
		}
		.hor_form{
			display: none;
		}
		.contact_item{
			padding: 0;
			display: flex;
			align-items: center;
			flex-direction: column;
			&_value{
				margin-top: 10px;
				max-width: 182px;
				text-align: center;
				line-height: 1.12;
			}
			&_icon{
				margin: 0;
				position: static;
			}
		}
	}
	.section_advantages{
		padding-bottom: 450px;
		&_worker{
			
		}
	}
	.section_form{
		.section_title{
			text-align: center;
		}
		&_text{
			text-align: center;
			br{
				display: none;
			}
		}
	}
	.section_gallery{
		flex-direction: column;
	}
	.gallery_item{
		&:nth-child(3n){
			display: none;
		}
	}
}
@media (max-width: 575px){
	.section_advantages{
		padding: 0 0 440px;
		.section_title{
			margin-bottom: 30px;
			text-align: center;
		}
		&_worker{
			width: 125%;
		}
	}
	.section_about{
		.section_title{
			text-align: center;
		}
		.advantage{
			&_text,&_title{
				text-align: center;
			}
			&_icon_wrap{
				justify-content: center;
			}
		}
	}
}
@media (max-width: 424px){
	.section_advantages{
		padding: 0 0 400px;
		&_worker{
			width: 180%;
		}
	}
}