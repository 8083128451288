$site-color: #0039D8;
$alt-color: #ED412F;
$text-color: #0F0F0F;
@font-face{
	font-family: Vremena Grotesk;
	src: url('../fonts/vremenagrotesk.woff2'),url('../fonts/vremenagrotesk.woff'),url('../fonts/vremenagrotesk.ttf');
}
@font-face{
	font-family: Vremena Grotesk;
	font-weight: 500;
	src: url('../fonts/vremenagroteskmedium.woff2'),url('../fonts/vremenagroteskmedium.woff'),url('../fonts/vremenagroteskmedium.ttf');
}
@font-face{
	font-family: Vremena Grotesk Book;
	src: url('../fonts/vremenagroteskbook.woff2'),url('../fonts/vremenagroteskbook.woff'),url('../fonts/vremenagroteskbook.ttf');
}
/*	Reset
---------------------------------------*/
*  {
	margin: 0;
	padding: 0;
	outline: 0;
}
*:focus, *:focus *{
	box-shadow: none !important;
}
html  {
	min-height: 100%;
	height: 100%;
}
body {
	min-width: 320px;
	margin: 0;
	padding: 0;
	background-size: cover;
}
html,body{
	&.opened{
		overflow: hidden;
	}
}
ol, ul  { 
	padding: 0;
	margin: 0;
}
li  {
	list-style-type: none;
}
.h1, .h2, .h3, .h4, h1, h2, h3, h4, p  {
	line-height: normal;
	margin: 0;
	padding: 0;
}
a:focus, button:focus, input:focus, textarea:focus  {
	outline: 0;
	text-decoration: none;
}
[data-toggle]{
	cursor: pointer;
}
a  {
	text-decoration: none;
	&:hover  {
		text-decoration: none;
	}
}
.logo{
	display: inline-block;
	svg{
		width: 240px;
		height: 50px;
	}
}
.contact_item{
    display: block;
    position: relative;
    padding: 2px 0 2px 44px;
    vertical-align: middle;
    line-height: 1;
    &:not(:last-child){
    	margin-bottom: 30px;
    }
    &_value{
    	display: inline-block;
    	font-size: 16px;
    	line-height: 1;
    	color: #fff;
        transition-duration: .4s
    }
    &_title{
    	margin-bottom: 10px;
    	font-weight: bold;
    }
    &.td-u{
    	.contact_item_value{
    		text-decoration: underline;
    	}
    }
    &.multiline{
    	.contact_item_icon{
    		top: 6px;
    		bottom: unset;
    	}
    	.contact_item_value{
    		display: block;
    	}
    }
    &.only_icon{
    	padding: 0;
    	.contact_item_icon{
    		position: static;
    		vertical-align: top;
    	}
    }
    &.no_icon{
    	padding: 0;
    }
    &_icon{
    	display: inline-block;
    	position: absolute;
    	left: 0;
    	top: 0;
    	bottom: 0;
    	margin: auto;
    	fill: $alt-color;
    }
}
a.contact_item:hover{
	.contact_item_value{
		color: $alt-color;
	}
}
.button{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 250px;
	height: 60px;
	max-width: 100%;
	font-size: 16px;
	line-height: 1;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
	border-radius: 3px;
	border: 0;
	background-color: $alt-color;
	cursor: pointer;
	outline: none;
	transition: .4s;
	&:hover{
		color: #fff;
		background-color: #DC1D09;
	}
}
.section_title{
	color: $text-color;
	font-size: 36px;
	line-height: 1.33;
	letter-spacing: -0.5px;
}
@media (min-width: 1200px){
	.container {
		max-width: 1200px;
	}
}
.form_wrap{
	max-width: 270px;
	position: relative;
	.input_wrap{
		position: relative;
		margin-bottom: 10px;
		input{
			height: 60px;
			width: 100%;
			padding: 24px 10px 18px 20px;
			font-size: 18px;
			line-height: 1;
			font-family: Vremena Grotesk Book;
			background-color: #fff;
			border-radius: 5px;
			border: 0;
			border: 1px solid #E3E1E1;
		}
	}
	.error_info{
		color: #f00;
		font-size: 13px;
		line-height: 1;
		text-align: center;
	}
	input[type="submit"]{
		border-radius: 5px;
	}
}
.placeholder{
	font-family: Vremena Grotesk Book;
	position: absolute;
	left: 20px;
	top: 22px;
	margin-bottom: 0;
	padding-bottom: 0;
	color: #a4a4a4;
	font-size: 16px;
	line-height: 1;
	font-weight: 500;
	cursor: text;
	transition-duration: .4s;
	will-change: transform;
	&.active{
		transform: translateY(-100%) scale(.85) translateX(-3px);
	}
}
.data{
	margin-top: 20px;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	letter-spacing: 0.1px;
	color: #a4a4a4;
	span{
		cursor: pointer;
		text-decoration: underline;
		&:hover{
			text-decoration: none;
		}
	}
}
.modal-dialog{
	max-width: 270px;
	margin: 30px auto;
}
.modal-content{
	border: 0;
	border-radius: 5px;
	padding: 45px 0 41px;
	.close{
		transition-duration: .3s;
		width: 15px;
		height: 15px;
		position: absolute;
		top: 20px;
		right: 20px;
		cursor: pointer;
		&:hover{
			opacity: 1 !important;
		}
	}
	.form_wrap{
		margin: 0 auto;
		position: static;
		form{
			max-width: 215px;
			margin: 0 auto;
		}
	}
	.modal_title{
		margin-bottom: 28px;
		font-size: 24px;
		line-height: 1;
	}
	.modal_text{
		font-size: 16px;
		line-height: 20px;
		padding-bottom: 30px;
	}
	.modal_text,.modal_title{
		text-align: center;
		color: #000;
	}
}
#modalPrivate,#modalData{
	.modal-dialog{
		max-width: 770px;
	}
	.modal-content{
		padding: 50px 60px 60px;
	}
	.form_header{
		font-size: 28px;
		line-height: 1.2;
		margin-bottom: 25px;
	}
}
.nano {
	height: 400px;
	.nano-content {
		padding-right: 20px;
		&:focus{
			outline: none;
		}
	}
	.nano-pane {
		width: 5px;
		right: 1px;
		border-radius: 100px;
		background-color: #d1d1d1;
		opacity: 1 !important;
		.nano-slider {
			width: 100%;
			margin: 0;
			background-color: $site-color;
			border-radius: 100px;
		}
	}
}
.mobile-menu-open {
	display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 22px;
    position: relative;
	cursor: pointer;
	background-color: transparent;
	border: 0;
	$this: &;
	&__button {
		width: 25px;
		height: 4px;
		background-color: #fff;
		&:after {
			top: 0;
		}
		&:before {
			bottom: 0;
		}
		&:after, &:before{
			content: "";
			display: block;
			width: 25px;
			height: 4px;
			background-color: #fff;
			position: absolute;
			right: 0;
			transition: .4s;
		}
	}
	&.active {
		#{ $this }__button {
			background-color: transparent;
			&:after {
				transform: rotate(-45deg);
				bottom: 0;
				margin: auto;
			}
			&:before {
				transform: rotate(45deg);
				top: 0;
				margin: auto;
			}
		}
	}
}



/* Mobile menu
---------------------------------------*/
.mobile-menu{
	flex-direction: column;
	justify-content: space-between;
	display: none;
    position: fixed;
    top: 77px;
    height: calc(100vh - 77px);
    width: 100vw;
	z-index: 3;
    background-color: #fff;
    transform: translateX(100%);
	will-change: transform;
    overflow-y: auto;
	overflow-x: hidden;
    transition: transform .4s linear;
	&.opened, &.active, .nav_close.opened{
		transform: translateX(0);
	}
	ul {
		margin-top: 0 !important;
		width: 100%;
		li {
			border-bottom: 1px solid #EBEBEB;
			a {
				display: block;
				position: relative;
				color: #828282;
				font-size: 15px;
				line-height: 18px;
				padding: 15px 20px;
				transition:.4s;
				display: flex;
				align-items: center;
				justify-content: space-between;
				> svg {
					width: 12px;
					height: 12px;
					fill: #828282;
					margin-left: 5px;
					transition:.4s;
				}
				&:hover {
					color: $site-color;
					> svg {
						fill: $site-color;
					}
				}
			}
			&.back {
				background-color: #EBEBEB;
				a {
					justify-content: flex-start;
					color: #828282;
					svg {
						fill: #828282;
						margin-left: 0;
						margin-right: 10px;
						transform: rotate(180deg);
					}
				}
			}
		}
	}
	> ul {
		> li {
			.sub-menu {
				position: fixed;
				top: 0;
				bottom: 0;
				transform: translateX(100%);
				will-change: transform;
				overflow-y: auto;
				overflow-x: hidden;
				width: 100vw;
				background-color: #fff;
				z-index: 1;
				transition: .3s linear;
				&.active {
					transform: translateX(0);
				}
			}
		}
	}
	&-contacts{
		width: 100%;
		padding: 20px;
		background-color: $site-color;
		&_title{
			font-size: 18px;
			line-height: 1;
			color: #fff;
			margin-bottom: 20px;
		}
		.modal_open{
			display: flex;
			height: 50px;
			padding: 0 15px;
			margin: 15px 0;
			&:hover{
				color: #fff;
			}
		}
		.soc_link{
			margin-bottom: 0;
			&_icon{
				fill: #fff;
			}
		}
		.contact_item{
			margin-bottom: 15px;
			padding: 2px 0 2px 34px;
			&_value{
				color: #fff;
				font-size: 15px;
			}
			&_icon{
				transform: scale(.7);
			}
		}
	}
}
@media(max-width: 991px){
	#modalPrivate,#modalData{
		.form_header{
			font-size: 24px;
			margin-bottom: 20px;
		}
		.modal-content{
			padding: 40px 15px 40px;
		}
	}
}
.preloader {
	position: absolute;
	z-index: 30;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(#fff, 0.9);
	&__figure {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 115px;
		height: 115px;
		margin: auto;
		&#{ & }_size {
			&_s {
				transform: scale(0.5);
			}
			&_m {
				transform: scale(0.79);
			}
			&_l {
				transform: scale(1);
			}
		}
	}
	&__item {
		position: absolute;
		background-color: transparent;
		width: 10px;
		height: 31px;
		border-radius: 5px;
		transform: scale(0.4);
		animation-name: fadeG;
		animation-duration: 1.2s;
		animation-iteration-count: infinite;
		animation-direction: normal;
		&-1 {
			top: 0;
			left: 10px;
			bottom: 0;
			animation-delay:0.45s;
			transform:rotate(-90deg);
			margin: auto 0;
		}
		&-2 {
			top: 11px;
			left: 22px;
			animation-delay:0.6s;
			transform:rotate(-45deg);
		}
		&-3 {
			top:0;
			left:0;
			right: 0;
			animation-delay:0.75s;
			transform:rotate(0deg);
			margin: 0 auto;
		}
		&-4 {
			top: 11px;
			right: 22px;
			animation-delay:0.9s;
			transform:rotate(45deg);
		}
		&-5 {
			top: 0;
			right: 10px;
			bottom: 0;
			animation-delay:1.05s;
			transform:rotate(90deg);
			margin: auto 0;
		}
		&-6 {
			right: 22px;
			bottom: 11px;
			animation-delay:1.2s;
			transform:rotate(135deg);
		}
		&-7 {
			left: 0;
			right: 0;
			bottom: 0;
			animation-delay:1.35s;
			transform:rotate(180deg);
			margin: 0 auto;
		}
		&-8 {
			left: 22px;
			bottom: 11px;
			animation-delay:1.5s;
			transform:rotate(-135deg);
		}
	}
}
@keyframes fadeG {
	0% {
		background-color: $alt-color;
	}

	100% {
		background-color: transparent;
	}
}
.preloader-wrapper,.sent{
	display: none;
	z-index: 1;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #fff;
}
.sent{
	flex-direction: column;
	justify-content: center;
	padding: 40px 25px;
	p{
		padding-bottom: 14px;
		font-size: 24px;
		line-height: 1.1;
		color: $text-color;
	}
	span{
		display: block;
		font-size: 16px;
		line-height: 1.1;
	}
	p,span{
		text-align: center;
	}
}
@mixin font-h1 {
	font-size: 42px;
	font-weight: 700;
	line-height: 46px;
}

@mixin font-h2 {
	font-size: 30px;
	font-weight: 700;
	line-height: 36px;
}

@mixin font-h3 {
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
}

@mixin font-p {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

@mixin font-sign {
	font: {
		weight: 400;
		size: 13px;
	}
}
.content {
	position: relative;
	
	/* Текст
	---------------------------------------*/
	&-text {
		>:last-child{
			margin-bottom: 0;
		}
		>:first-child{
			margin-top: 0;
		}
		h1 {
			margin-bottom: 14px;
			@include font-h1;
		}
		h2 {
			margin-bottom: 10px;
			padding-top: 9px;
			@include font-h2;
		}
		h3 {
			padding-top: 9px;
			margin-bottom: 4px;
			@include font-h3;
		}
		p {
			margin-bottom: 15px;
			@include font-p;
		}
		>b{
			display: block;
			margin-bottom: 20px;
			@include font-h3;
		}
		a {
			text-decoration: underline;
			transition:.2s;
			color: #6cbc29;
			&:visited{
				color: #7a339e !important;
			}
			&:hover, &:focus {
				color: #4e8d18 !important;
			}
		}
		ol + h3, ul + h3{
			padding-top: 15px;
		}
		ol {
			margin-left: 30px;
			> li {
				@include font-p;
				list-style-type: decimal;
				padding-bottom: 8px;
				padding-left: 8px;
			}
			ul{
				li:last-child{
					padding-bottom: 0;
				}
			}
		}
		ul {
			margin-left: 35px;
			> li {
				position: relative;
				@include font-p;
				padding-bottom: 8px;
				&:before {
					content: '–';
					position: absolute;
					left: -20px;
				}
			}
		}
		img {
			width: 100%;
			height: auto;
			-o-object-fit: cover;
			object-fit: cover;
			margin: 15px 0 6px;
			&.noresize{
				height: auto !important;
			}
		}
	}
}