@charset "UTF-8";
@font-face {
  font-family: Vremena Grotesk;
  src: url("../fonts/vremenagrotesk.woff2"), url("../fonts/vremenagrotesk.woff"), url("../fonts/vremenagrotesk.ttf"); }

@font-face {
  font-family: Vremena Grotesk;
  font-weight: 500;
  src: url("../fonts/vremenagroteskmedium.woff2"), url("../fonts/vremenagroteskmedium.woff"), url("../fonts/vremenagroteskmedium.ttf"); }

@font-face {
  font-family: Vremena Grotesk Book;
  src: url("../fonts/vremenagroteskbook.woff2"), url("../fonts/vremenagroteskbook.woff"), url("../fonts/vremenagroteskbook.ttf"); }

/*	Reset
---------------------------------------*/
* {
  margin: 0;
  padding: 0;
  outline: 0; }

*:focus, *:focus * {
  box-shadow: none !important; }

html {
  min-height: 100%;
  height: 100%; }

body {
  min-width: 320px;
  margin: 0;
  padding: 0;
  background-size: cover; }

html.opened, body.opened {
  overflow: hidden; }

ol, ul {
  padding: 0;
  margin: 0; }

li {
  list-style-type: none; }

.h1, .h2, .h3, .h4, h1, h2, h3, h4, p {
  line-height: normal;
  margin: 0;
  padding: 0; }

a:focus, button:focus, input:focus, textarea:focus {
  outline: 0;
  text-decoration: none; }

[data-toggle] {
  cursor: pointer; }

a {
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

.logo {
  display: inline-block; }
  .logo svg {
    width: 240px;
    height: 50px; }

.contact_item {
  display: block;
  position: relative;
  padding: 2px 0 2px 44px;
  vertical-align: middle;
  line-height: 1; }
  .contact_item:not(:last-child) {
    margin-bottom: 30px; }
  .contact_item_value {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    color: #fff;
    transition-duration: .4s; }
  .contact_item_title {
    margin-bottom: 10px;
    font-weight: bold; }
  .contact_item.td-u .contact_item_value {
    text-decoration: underline; }
  .contact_item.multiline .contact_item_icon {
    top: 6px;
    bottom: unset; }
  .contact_item.multiline .contact_item_value {
    display: block; }
  .contact_item.only_icon {
    padding: 0; }
    .contact_item.only_icon .contact_item_icon {
      position: static;
      vertical-align: top; }
  .contact_item.no_icon {
    padding: 0; }
  .contact_item_icon {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    fill: #ED412F; }

a.contact_item:hover .contact_item_value {
  color: #ED412F; }

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 60px;
  max-width: 100%;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-radius: 3px;
  border: 0;
  background-color: #ED412F;
  cursor: pointer;
  outline: none;
  transition: .4s; }
  .button:hover {
    color: #fff;
    background-color: #DC1D09; }

.section_title {
  color: #0F0F0F;
  font-size: 36px;
  line-height: 1.33;
  letter-spacing: -0.5px; }

@media (min-width: 1200px) {
  .container {
    max-width: 1200px; } }

.form_wrap {
  max-width: 270px;
  position: relative; }
  .form_wrap .input_wrap {
    position: relative;
    margin-bottom: 10px; }
    .form_wrap .input_wrap input {
      height: 60px;
      width: 100%;
      padding: 24px 10px 18px 20px;
      font-size: 18px;
      line-height: 1;
      font-family: Vremena Grotesk Book;
      background-color: #fff;
      border-radius: 5px;
      border: 0;
      border: 1px solid #E3E1E1; }
  .form_wrap .error_info {
    color: #f00;
    font-size: 13px;
    line-height: 1;
    text-align: center; }
  .form_wrap input[type="submit"] {
    border-radius: 5px; }

.placeholder {
  font-family: Vremena Grotesk Book;
  position: absolute;
  left: 20px;
  top: 22px;
  margin-bottom: 0;
  padding-bottom: 0;
  color: #a4a4a4;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  cursor: text;
  transition-duration: .4s;
  will-change: transform; }
  .placeholder.active {
    transform: translateY(-100%) scale(0.85) translateX(-3px); }

.data {
  margin-top: 20px;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.1px;
  color: #a4a4a4; }
  .data span {
    cursor: pointer;
    text-decoration: underline; }
    .data span:hover {
      text-decoration: none; }

.modal-dialog {
  max-width: 270px;
  margin: 30px auto; }

.modal-content {
  border: 0;
  border-radius: 5px;
  padding: 45px 0 41px; }
  .modal-content .close {
    transition-duration: .3s;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer; }
    .modal-content .close:hover {
      opacity: 1 !important; }
  .modal-content .form_wrap {
    margin: 0 auto;
    position: static; }
    .modal-content .form_wrap form {
      max-width: 215px;
      margin: 0 auto; }
  .modal-content .modal_title {
    margin-bottom: 28px;
    font-size: 24px;
    line-height: 1; }
  .modal-content .modal_text {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 30px; }
  .modal-content .modal_text, .modal-content .modal_title {
    text-align: center;
    color: #000; }

#modalPrivate .modal-dialog, #modalData .modal-dialog {
  max-width: 770px; }

#modalPrivate .modal-content, #modalData .modal-content {
  padding: 50px 60px 60px; }

#modalPrivate .form_header, #modalData .form_header {
  font-size: 28px;
  line-height: 1.2;
  margin-bottom: 25px; }

.nano {
  height: 400px; }
  .nano .nano-content {
    padding-right: 20px; }
    .nano .nano-content:focus {
      outline: none; }
  .nano .nano-pane {
    width: 5px;
    right: 1px;
    border-radius: 100px;
    background-color: #d1d1d1;
    opacity: 1 !important; }
    .nano .nano-pane .nano-slider {
      width: 100%;
      margin: 0;
      background-color: #0039D8;
      border-radius: 100px; }

.mobile-menu-open {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0; }
  .mobile-menu-open__button {
    width: 25px;
    height: 4px;
    background-color: #fff; }
    .mobile-menu-open__button:after {
      top: 0; }
    .mobile-menu-open__button:before {
      bottom: 0; }
    .mobile-menu-open__button:after, .mobile-menu-open__button:before {
      content: "";
      display: block;
      width: 25px;
      height: 4px;
      background-color: #fff;
      position: absolute;
      right: 0;
      transition: .4s; }
  .mobile-menu-open.active .mobile-menu-open__button {
    background-color: transparent; }
    .mobile-menu-open.active .mobile-menu-open__button:after {
      transform: rotate(-45deg);
      bottom: 0;
      margin: auto; }
    .mobile-menu-open.active .mobile-menu-open__button:before {
      transform: rotate(45deg);
      top: 0;
      margin: auto; }

/* Mobile menu
---------------------------------------*/
.mobile-menu {
  flex-direction: column;
  justify-content: space-between;
  display: none;
  position: fixed;
  top: 77px;
  height: calc(100vh - 77px);
  width: 100vw;
  z-index: 3;
  background-color: #fff;
  transform: translateX(100%);
  will-change: transform;
  overflow-y: auto;
  overflow-x: hidden;
  transition: transform .4s linear; }
  .mobile-menu.opened, .mobile-menu.active, .mobile-menu .nav_close.opened {
    transform: translateX(0); }
  .mobile-menu ul {
    margin-top: 0 !important;
    width: 100%; }
    .mobile-menu ul li {
      border-bottom: 1px solid #EBEBEB; }
      .mobile-menu ul li a {
        display: block;
        position: relative;
        color: #828282;
        font-size: 15px;
        line-height: 18px;
        padding: 15px 20px;
        transition: .4s;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .mobile-menu ul li a > svg {
          width: 12px;
          height: 12px;
          fill: #828282;
          margin-left: 5px;
          transition: .4s; }
        .mobile-menu ul li a:hover {
          color: #0039D8; }
          .mobile-menu ul li a:hover > svg {
            fill: #0039D8; }
      .mobile-menu ul li.back {
        background-color: #EBEBEB; }
        .mobile-menu ul li.back a {
          justify-content: flex-start;
          color: #828282; }
          .mobile-menu ul li.back a svg {
            fill: #828282;
            margin-left: 0;
            margin-right: 10px;
            transform: rotate(180deg); }
  .mobile-menu > ul > li .sub-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    transform: translateX(100%);
    will-change: transform;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100vw;
    background-color: #fff;
    z-index: 1;
    transition: .3s linear; }
    .mobile-menu > ul > li .sub-menu.active {
      transform: translateX(0); }
  .mobile-menu-contacts {
    width: 100%;
    padding: 20px;
    background-color: #0039D8; }
    .mobile-menu-contacts_title {
      font-size: 18px;
      line-height: 1;
      color: #fff;
      margin-bottom: 20px; }
    .mobile-menu-contacts .modal_open {
      display: flex;
      height: 50px;
      padding: 0 15px;
      margin: 15px 0; }
      .mobile-menu-contacts .modal_open:hover {
        color: #fff; }
    .mobile-menu-contacts .soc_link {
      margin-bottom: 0; }
      .mobile-menu-contacts .soc_link_icon {
        fill: #fff; }
    .mobile-menu-contacts .contact_item {
      margin-bottom: 15px;
      padding: 2px 0 2px 34px; }
      .mobile-menu-contacts .contact_item_value {
        color: #fff;
        font-size: 15px; }
      .mobile-menu-contacts .contact_item_icon {
        transform: scale(0.7); }

@media (max-width: 991px) {
  #modalPrivate .form_header, #modalData .form_header {
    font-size: 24px;
    margin-bottom: 20px; }
  #modalPrivate .modal-content, #modalData .modal-content {
    padding: 40px 15px 40px; } }

.preloader {
  position: absolute;
  z-index: 30;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); }
  .preloader__figure {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 115px;
    height: 115px;
    margin: auto; }
    .preloader__figure.preloader__figure_size_s {
      transform: scale(0.5); }
    .preloader__figure.preloader__figure_size_m {
      transform: scale(0.79); }
    .preloader__figure.preloader__figure_size_l {
      transform: scale(1); }
  .preloader__item {
    position: absolute;
    background-color: transparent;
    width: 10px;
    height: 31px;
    border-radius: 5px;
    transform: scale(0.4);
    animation-name: fadeG;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-direction: normal; }
    .preloader__item-1 {
      top: 0;
      left: 10px;
      bottom: 0;
      animation-delay: 0.45s;
      transform: rotate(-90deg);
      margin: auto 0; }
    .preloader__item-2 {
      top: 11px;
      left: 22px;
      animation-delay: 0.6s;
      transform: rotate(-45deg); }
    .preloader__item-3 {
      top: 0;
      left: 0;
      right: 0;
      animation-delay: 0.75s;
      transform: rotate(0deg);
      margin: 0 auto; }
    .preloader__item-4 {
      top: 11px;
      right: 22px;
      animation-delay: 0.9s;
      transform: rotate(45deg); }
    .preloader__item-5 {
      top: 0;
      right: 10px;
      bottom: 0;
      animation-delay: 1.05s;
      transform: rotate(90deg);
      margin: auto 0; }
    .preloader__item-6 {
      right: 22px;
      bottom: 11px;
      animation-delay: 1.2s;
      transform: rotate(135deg); }
    .preloader__item-7 {
      left: 0;
      right: 0;
      bottom: 0;
      animation-delay: 1.35s;
      transform: rotate(180deg);
      margin: 0 auto; }
    .preloader__item-8 {
      left: 22px;
      bottom: 11px;
      animation-delay: 1.5s;
      transform: rotate(-135deg); }

@keyframes fadeG {
  0% {
    background-color: #ED412F; }
  100% {
    background-color: transparent; } }

.preloader-wrapper, .sent {
  display: none;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff; }

.sent {
  flex-direction: column;
  justify-content: center;
  padding: 40px 25px; }
  .sent p {
    padding-bottom: 14px;
    font-size: 24px;
    line-height: 1.1;
    color: #0F0F0F; }
  .sent span {
    display: block;
    font-size: 16px;
    line-height: 1.1; }
  .sent p, .sent span {
    text-align: center; }

.content {
  position: relative;
  /* Текст
	---------------------------------------*/ }
  .content-text > :last-child {
    margin-bottom: 0; }
  .content-text > :first-child {
    margin-top: 0; }
  .content-text h1 {
    margin-bottom: 14px;
    font-size: 42px;
    font-weight: 700;
    line-height: 46px; }
  .content-text h2 {
    margin-bottom: 10px;
    padding-top: 9px;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px; }
  .content-text h3 {
    padding-top: 9px;
    margin-bottom: 4px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px; }
  .content-text p {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; }
  .content-text > b {
    display: block;
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px; }
  .content-text a {
    text-decoration: underline;
    transition: .2s;
    color: #6cbc29; }
    .content-text a:visited {
      color: #7a339e !important; }
    .content-text a:hover, .content-text a:focus {
      color: #4e8d18 !important; }
  .content-text ol + h3, .content-text ul + h3 {
    padding-top: 15px; }
  .content-text ol {
    margin-left: 30px; }
    .content-text ol > li {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      list-style-type: decimal;
      padding-bottom: 8px;
      padding-left: 8px; }
    .content-text ol ul li:last-child {
      padding-bottom: 0; }
  .content-text ul {
    margin-left: 35px; }
    .content-text ul > li {
      position: relative;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding-bottom: 8px; }
      .content-text ul > li:before {
        content: '–';
        position: absolute;
        left: -20px; }
  .content-text img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    margin: 15px 0 6px; }
    .content-text img.noresize {
      height: auto !important; }

.page_404 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%; }

.section_404 {
  flex: 1 0 auto; }

.body_404 {
  height: 100%;
  padding: 110px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0F0F0F; }
  .body_404 .section_title {
    margin-bottom: 22px;
    text-align: center; }

.text_404 {
  max-width: 770px;
  margin: 0 0 40px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center; }

.number_404 {
  font-size: 200px;
  line-height: 1;
  color: #0F0F0F;
  text-align: center; }

.button_404 {
  width: 270px; }

@media (max-width: 991px) {
  .number_404 {
    font-size: 150px; } }

body {
  font-family: Vremena Grotesk;
  position: relative; }

.section_main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background-color: #0039D8; }
  .section_main_top {
    position: relative; }
  .section_main_bottom {
    border-top: 1px solid #2255E2;
    background-color: #0039D8;
    width: 100vw; }
    .section_main_bottom.fixed {
      position: fixed;
      top: 0;
      z-index: 5;
      border-top: 0; }
      .section_main_bottom.fixed .menu_slider_item {
        padding-top: 25px;
        padding-bottom: 25px; }
  .section_main .title {
    margin: 0 0 17px;
    font-size: 48px;
    line-height: 1;
    letter-spacing: -.5px;
    color: #FFFFFF;
    font-weight: 400; }
  .section_main .text {
    margin-bottom: 40px;
    font-family: Vremena Grotesk Book;
    font-size: 18px;
    line-height: 1.56;
    color: #FFFFFF; }
  .section_main_bg_logo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 232px;
    padding: 0 60px;
    object-fit: contain; }
  .section_main_text_wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; }

.menu_slider {
  display: flex;
  justify-content: space-between;
  margin: 0 -65px;
  position: relative;
  z-index: 1;
  overflow: auto; }
  .menu_slider_item {
    padding: 60px 0;
    font-size: 20px;
    flex-grow: 1;
    line-height: 1;
    color: #fff;
    text-align: center;
    transition: .4s; }
    .menu_slider_item:hover {
      color: #fff;
      background-color: #ED412F; }

.truba_pic {
  display: block;
  filter: drop-shadow(0px 60px 70px #0031B9); }

.header {
  padding: 30px 0;
  height: 100px;
  width: 100%;
  font-size: 18px;
  position: relative;
  z-index: 3;
  background-color: #0039D8; }
  .header_wrap {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header .button {
    width: 200px;
    height: 40px;
    padding-top: 2px;
    font-size: 14px;
    letter-spacing: 1.75px;
    color: #fff;
    background-color: transparent;
    border: 2px solid #fff; }
    .header .button:hover {
      background-color: #DC1D09;
      border-color: #DC1D09; }
  .header .contact_item {
    margin: 0; }
  .header_items .contact_item {
    display: inline-block;
    margin-right: 22px; }
    .header_items .contact_item_icon {
      fill: #fff; }
  .header_items > * {
    vertical-align: middle; }

.section_services {
  padding: 80px 0 100px; }
  .section_services .section_title {
    margin-bottom: 44px;
    text-align: center; }

.section_about {
  padding: 120px 0 200px; }
  .section_about .section_title {
    margin-bottom: 170px; }
  .section_about .advantage_text {
    font-family: Vremena Grotesk;
    font-size: 17px; }

.section_advantages {
  padding: 47px 0 424px;
  background: center/cover no-repeat;
  position: relative; }
  .section_advantages .section_title {
    margin-bottom: 70px; }
  .section_advantages_worker {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right bottom; }

.advantage {
  margin-bottom: 50px;
  color: #0F0F0F; }
  .advantage_icon {
    fill: #ED412F; }
  .advantage_title {
    margin-bottom: 13px;
    font-size: 20px;
    line-height: 1;
    font-weight: 500; }
  .advantage_text {
    font-family: Vremena Grotesk Book;
    font-size: 18px;
    line-height: 1.33; }
  .advantage_icon_wrap {
    height: 50px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px; }

.service {
  height: 480px;
  max-width: 370px;
  margin: 0 auto;
  position: relative; }
  .service_title {
    max-width: 220px;
    margin: 0 auto;
    height: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 1.3;
    color: #0039D8;
    text-align: center;
    transition: color .4s; }
  .service::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    max-width: 270px;
    margin: auto;
    background-color: #EAEAEA;
    border-radius: 10px;
    transition: background-color .4s; }
  .service_img_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 65%; }
    .service_img_wrap .service_img {
      margin: 0; }
  .service_img {
    max-width: 100%;
    margin-top: -14px;
    filter: grayscale(100%);
    transition: filter .4s; }
  .service:hover .service_title {
    color: #fff; }
  .service:hover .service_img {
    filter: grayscale(0); }
  .service:hover::before {
    background-color: #0039D8; }

.hor_form {
  padding-top: 30px; }
  .hor_form .form_wrap {
    width: 100%;
    max-width: 430px;
    padding-bottom: 4px; }
    .hor_form .form_wrap form {
      display: flex; }
      .hor_form .form_wrap form.sending {
        opacity: 0; }
        .hor_form .form_wrap form.sending + .data {
          opacity: 0; }
    .hor_form .form_wrap .input_wrap {
      width: 170px;
      margin: 0 10px 0 0; }
      .hor_form .form_wrap .input_wrap input {
        font-size: 15px; }
  .hor_form .data {
    font-size: 16px;
    line-height: 1;
    color: #4B78F6;
    font-family: Vremena Grotesk Book; }
  .hor_form .preloader-wrapper, .hor_form .sent {
    background-color: transparent; }
  .hor_form .sent {
    padding: 0; }
    .hor_form .sent p {
      padding: 0;
      color: #fff; }

.section_form {
  padding: 77px 0 0;
  background-color: #0039D8; }
  .section_form .section_title {
    margin-bottom: 12px;
    color: #fff; }
  .section_form_text {
    color: #fff;
    font-size: 18px;
    line-height: 28px; }
  .section_form_phone_wrap {
    position: relative;
    width: 100%;
    height: 100%; }
  .section_form_phone {
    margin-top: -133px;
    max-width: 100%;
    display: block !important;
    object-position: center;
    object-fit: contain; }

.section_gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row; }

.section_docs {
  padding: 140px 0 145px;
  background-color: #0039D8; }
  .section_docs .section_title {
    margin-bottom: 60px;
    color: #fff; }

.docs_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.doc {
  width: 200px;
  height: 288px;
  position: relative;
  margin-bottom: 30px; }
  .doc:hover::after {
    opacity: .3; }
  .doc::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    transition: opacity .4s; }
  .doc_img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center; }

.gallery_item {
  display: block;
  height: 432px;
  flex: 1 0 33%;
  position: relative; }
  .gallery_item:hover::after {
    opacity: .3; }
  .gallery_item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    transition: opacity .4s; }

.gallery_img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; }

.section_reviews {
  padding: 120px 0 120px; }
  .section_reviews .section_title {
    margin-bottom: 60px; }
  .section_reviews .container {
    position: relative; }

.reviews_slider {
  position: relative; }
  .reviews_slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 88.5%;
    background: #FAFAFA;
    border-radius: 5px; }
  .reviews_slider_slide {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 57px 0 57px 100px;
    max-width: 88.5%;
    height: 516px; }
  .reviews_slider_info {
    max-width: 82.4%; }
    .reviews_slider_info_top {
      margin-bottom: 39px; }
  .reviews_slider_fio {
    margin: 0 0 10px;
    font-size: 20px;
    line-height: 1;
    color: #0F0F0F;
    font-weight: 500; }
  .reviews_slider_text {
    font-family: Vremena Grotesk Book;
    font-size: 18px;
    line-height: 26px;
    color: #0F0F0F; }
  .reviews_slider_dolzhnost {
    font-family: Vremena Grotesk Book;
    font-size: 15px;
    line-height: 1;
    color: #a4a4a4;
    margin-bottom: 25px; }
  .reviews_slider_doc {
    display: block;
    width: 270px;
    height: 402px;
    flex: 0 0 auto;
    margin-right: -135px;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08); }
    .reviews_slider_doc:hover::after {
      opacity: .3; }
    .reviews_slider_doc::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      background-color: #fff;
      transition-duration: .4s; }
  .reviews_slider_controls {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 300px;
    bottom: 67px; }
    .reviews_slider_controls .arrow {
      width: 68px;
      height: 68px;
      border-radius: 68px;
      cursor: pointer;
      border: 0;
      background-color: transparent;
      transition: background-color .4s; }
      .reviews_slider_controls .arrow svg {
        width: 12px;
        height: 20px;
        fill: #0039D8; }
      .reviews_slider_controls .arrow:hover {
        background-color: #EAEAEA; }
      .reviews_slider_controls .arrow.next {
        order: 1; }
        .reviews_slider_controls .arrow.next svg {
          transform: rotate(-180deg); }
    .reviews_slider_controls .slick-dots {
      padding: 0 80px 2px; }
      .reviews_slider_controls .slick-dots li {
        display: inline-block;
        width: 6px;
        height: 6px;
        margin: 0 5px;
        background-color: #0039D8;
        border-radius: 10px;
        cursor: pointer;
        transition-duration: .4s; }
        .reviews_slider_controls .slick-dots li.slick-active {
          transform: scale(1.2);
          background-color: #ED412F; }

.footer {
  overflow: hidden;
  background-color: #0039D8; }
  .footer .col-md-6 > * {
    position: relative;
    z-index: 1; }
  .footer .col-md-6:first-child {
    padding-right: 0;
    padding-top: 120px;
    padding-bottom: 190px; }
  .footer .col-md-6:last-child {
    padding-left: 0; }
  .footer .secton_title {
    margin-bottom: 39px;
    font-size: 36px;
    line-height: 1;
    color: #fff; }
  .footer .top {
    position: relative; }
  .footer .bottom {
    background-color: #252525;
    padding: 29px 0 27px;
    position: relative;
    z-index: 1; }
    .footer .bottom .text {
      color: #777;
      font-size: 16px;
      line-height: 1;
      display: block; }
      .footer .bottom .text svg {
        width: 120px;
        height: 24px;
        margin-left: 5px;
        fill: #777; }
      .footer .bottom .text.made_by {
        margin-top: -3px;
        float: right; }
      .footer .bottom .text[data-toggle]:hover {
        text-decoration: underline; }
  .footer .map {
    position: relative;
    z-index: 1;
    width: 50vw;
    height: 100%; }
    .footer .map .markers {
      display: none; }

@media (min-width: 1300px) and (max-width: 1440px) {
  .truba_pic {
    margin-left: 150px; } }

@media (min-width: 1300px) {
  .section_main {
    min-height: 520px;
    height: calc(100vh - 100px); }
  .truba_pic {
    min-height: 390px;
    height: 63vh; } }

@media (min-width: 1200px) {
  .header_items {
    display: none; } }

@media (max-width: 1199px) {
  .section_services {
    padding: 50px 0 60px; }
  .service {
    height: 390px;
    margin-bottom: 30px; }
    .service_img_wrap {
      height: 60%; }
    .service_title {
      height: 40%; }
  .section_main .title {
    margin: 0 0 10px;
    font-size: 36px;
    line-height: 1.17;
    letter-spacing: -0.375px; }
  .section_main .text {
    margin-bottom: 23px;
    font-size: 16px;
    line-height: 1.5; }
  .section_main_top {
    padding: 33px 0 41px; }
  .section_main_bottom {
    position: static !important;
    z-index: 1 !important; }
  .truba_pic {
    max-height: 350px; }
  .menu_slider {
    margin: 0 -15px; }
    .menu_slider_item {
      padding: 40px 26px; }
  .mobile-menu {
    display: flex; }
  body {
    padding-top: 77px; }
  .header {
    position: fixed;
    top: 0;
    height: auto;
    padding: 23px 0; }
    .header_contacts {
      display: none; }
  .logo svg {
    width: 170px;
    height: 31.45px; }
  .footer .col-md-6:first-child {
    padding: 60px 0 60px 15px; }
  .section_advantages {
    padding: 40px 0 260px; }
    .section_advantages_worker {
      width: 125%;
      object-position: 200px bottom;
      object-fit: contain; }
  .section_about {
    padding: 50px 0 70px; }
    .section_about .section_title {
      margin-bottom: 39px; }
  .section_form {
    padding: 46px 0 20px; }
    .section_form_phone {
      margin-top: -90px; }
  .section_docs {
    padding: 50px 0 33px; }
    .section_docs .section_title {
      margin-bottom: 30px; }
  .docs_wrap {
    flex-wrap: nowrap;
    overflow: auto;
    justify-content: flex-start; }
  .doc {
    margin-right: 30px;
    flex: 1 0 auto; }
  .section_reviews {
    padding: 70px 0; }
  .reviews_slider_slide {
    height: 430px;
    padding: 35px 0 35px 55px; }
  .reviews_slider_doc {
    width: 193px;
    height: 270px;
    margin-right: -96px; }
  .reviews_slider_controls {
    left: 250px;
    bottom: 40px; } }

@media (max-width: 991px) {
  .hor_form .form_wrap {
    max-width: 270px; }
    .hor_form .form_wrap form {
      flex-direction: column; }
    .hor_form .form_wrap .input_wrap {
      width: 100%;
      margin: 0 0 10px 0; }
    .hor_form .form_wrap input[type="submit"] {
      width: 100%; }
  .hor_form .data {
    font-size: 14px; }
  .section_form_phone {
    max-width: 290px;
    display: block !important;
    margin: -80px auto 0; }
  .section_reviews {
    padding: 50px 0 0; }
    .section_reviews .section_title {
      margin-bottom: 36px;
      text-align: center; }
  .reviews_slider_controls {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    left: 0;
    right: 0;
    bottom: 20px; }
    .reviews_slider_controls .slick-dots {
      padding: 0 0 2px; }
  .reviews_slider_info {
    max-width: none; }
    .reviews_slider_info_top {
      margin-bottom: 30px; }
  .reviews_slider_slide {
    padding: 30px 20px 128px;
    flex-direction: column;
    height: auto;
    max-width: none; }
  .reviews_slider_doc {
    margin: 15px auto 0; }
  .reviews_slider_text {
    font-size: 16px; }
  .reviews_slider::before {
    max-width: none; }
  .section_title {
    font-size: 30px;
    line-height: 1.2;
    letter-spacing: -0.416667px; }
  .section_main .bottom {
    padding-top: 40px; }
    .section_main .bottom .title {
      font-size: 46px;
      line-height: 1;
      letter-spacing: -0.8px;
      padding-bottom: 20px; }
    .section_main .bottom .warning {
      font-size: 12px;
      line-height: 1;
      padding: 90px 0 30px; }
  .footer .bottom {
    padding: 20px 0 28px; }
    .footer .bottom .text {
      margin-top: 12px;
      text-align: center; }
      .footer .bottom .text.made_by {
        float: none;
        margin-top: 12px; }
  .section_advantages {
    padding-bottom: 540px; }
    .section_advantages .advantage {
      margin-bottom: 30px; }
    .section_advantages_worker {
      width: 100%;
      object-position: right bottom; } }

@media (max-width: 767px) {
  .section_main .title {
    text-align: center; }
  .section_main .text {
    text-align: center; }
    .section_main .text br {
      display: none; }
  .section_main .button {
    margin: 0 auto; }
  .section_main_bg_logo {
    display: none; }
  .hor_form .form_wrap {
    margin: 0 auto; }
  .truba_col {
    display: none; }
  .footer .map {
    width: auto;
    height: 370px; }
  .footer .col-md-6:first-child {
    padding: 50px 15px 30px !important; }
  .footer .col-md-6:last-child {
    padding: 0; }
  .footer .secton_title {
    text-align: center; }
  .footer .hor_form {
    display: none; }
  .footer .contact_item {
    padding: 0;
    display: flex;
    align-items: center;
    flex-direction: column; }
    .footer .contact_item_value {
      margin-top: 10px;
      max-width: 182px;
      text-align: center;
      line-height: 1.12; }
    .footer .contact_item_icon {
      margin: 0;
      position: static; }
  .section_advantages {
    padding-bottom: 450px; }
  .section_form .section_title {
    text-align: center; }
  .section_form_text {
    text-align: center; }
    .section_form_text br {
      display: none; }
  .section_gallery {
    flex-direction: column; }
  .gallery_item:nth-child(3n) {
    display: none; } }

@media (max-width: 575px) {
  .section_advantages {
    padding: 0 0 440px; }
    .section_advantages .section_title {
      margin-bottom: 30px;
      text-align: center; }
    .section_advantages_worker {
      width: 125%; }
  .section_about .section_title {
    text-align: center; }
  .section_about .advantage_text, .section_about .advantage_title {
    text-align: center; }
  .section_about .advantage_icon_wrap {
    justify-content: center; } }

@media (max-width: 424px) {
  .section_advantages {
    padding: 0 0 400px; }
    .section_advantages_worker {
      width: 180%; } }
