@import 'general';
@import 'components/page_404';
body{
	font-family: Vremena Grotesk;
	position: relative;
}
.section_main{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	background-color: $site-color;
	&_top{
		position: relative;
	}
	&_bottom{
		border-top: 1px solid #2255E2;
		background-color: $site-color;
		width: 100vw;
		&.fixed{
			position: fixed;
			top: 0;
			z-index: 5;
			border-top: 0;
			.menu_slider_item{
				padding-top: 25px;
				padding-bottom: 25px;
			}
		}
	}
	.title{
		margin: 0 0 17px;
		font-size: 48px;
		line-height: 1;
		letter-spacing: -.5px;
		color: #FFFFFF;
		font-weight: 400;
	}
	.text{
		margin-bottom: 40px;
		font-family: Vremena Grotesk Book;
		font-size: 18px;
		line-height: 1.56;
		color: #FFFFFF;
	}
	&_bg_logo{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 100%;
		height: 232px;
		padding: 0 60px;
		object-fit: contain;
	}
	&_text_wrap{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
.menu_slider{
	display: flex;
	justify-content: space-between;
	margin: 0 -65px;
	position: relative;
	z-index: 1;
	overflow: auto;
	&_item{
		padding: 60px 0;
		font-size: 20px;
		flex-grow: 1;
		line-height: 1;
		color: #fff;
		text-align: center;
		transition: .4s;
		&:hover{
			color: #fff;
			background-color: $alt-color;
		}
	}
}
.truba_pic{
	display: block;
	filter: drop-shadow(0px 60px 70px #0031B9);
}
.header{
	padding: 30px 0;
	height: 100px;
	width: 100%;
	font-size: 18px;
	position: relative;
	z-index: 3;
	background-color: $site-color;
	&_wrap{
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.button{
		width: 200px;
		height: 40px;
		padding-top: 2px;
		font-size: 14px;
		letter-spacing: 1.75px;
		color: #fff;
		background-color: transparent;
		border: 2px solid #fff;
		&:hover{
			background-color: #DC1D09;
			border-color: #DC1D09;
		}
	}
	.contact_item{
		margin: 0;
	}
	&_items{
		.contact_item{
			display: inline-block;
			margin-right: 22px;
			&_icon{
				fill: #fff;
			}
		}
		>*{
			vertical-align: middle;
		}
	}
}
.section_services{
	padding: 80px 0 100px;
	.section_title{
		margin-bottom: 44px;
		text-align: center;
	}
}
.section_about{
	padding: 120px 0 200px;
	.section_title{
		margin-bottom: 170px;
	}
	.advantage{
		&_text{
			font-family: Vremena Grotesk;
			font-size: 17px;
		}
	}
}
.section_advantages{
	padding: 47px 0 424px;
	background: center/cover no-repeat;
	position: relative;
	.section_title{
		margin-bottom: 70px;
	}
	&_worker{
		position: absolute;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: right bottom;
	}
}
.advantage{
	margin-bottom: 50px;
	color: $text-color;
	&_icon{
		fill: $alt-color;
	}
	&_title{
		margin-bottom: 13px;
		font-size: 20px;
		line-height: 1;
		font-weight: 500;
	}
	&_text{
		font-family: Vremena Grotesk Book;
		font-size: 18px;
		line-height: 1.33;
	}
	&_icon_wrap{
		height: 50px;
		display: flex;
		align-items: flex-end;
		margin-bottom: 20px;
	}
}
.service{
	height: 480px;
	max-width: 370px;
	margin: 0 auto;
	position: relative;
	&_title{
		max-width: 220px;
		margin: 0 auto;
		height: 35%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
		line-height: 1.3;
		color: $site-color;
		text-align: center;
		transition: color .4s;
	}
	&::before{
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1;
		max-width: 270px;
		margin: auto;
		background-color: #EAEAEA;
		border-radius: 10px;
		transition: background-color .4s;
	}
	&_img_wrap{
		display: flex;
		justify-content: center;
		align-items: center;
		height: 65%;
		.service_img{
			margin: 0;
		}
	}
	&_img{
		max-width: 100%;
		margin-top: -14px;
		filter: grayscale(100%);
		transition: filter .4s;
	}
	&:hover{
		.service_title{
			color: #fff;
		}
		.service_img{
			filter: grayscale(0);
		}
		&::before{
			background-color: $site-color;
		}
	}
}
.hor_form{
	padding-top: 30px;
	.form_wrap{
		width: 100%;
		max-width: 430px;
		padding-bottom: 4px;
		form{
			display: flex;
			&.sending{
				opacity: 0;
				&+.data{
					opacity: 0;
				}
			}
		}
		.input_wrap{
			width: 170px;
			margin: 0 10px 0 0;
			input{
				font-size: 15px;
			}
		}
	}
	.data{
		font-size: 16px;
		line-height: 1;
		color: #4B78F6;
		font-family: Vremena Grotesk Book;
	}
	.preloader-wrapper, .sent{
		background-color: transparent;
	}
	.sent{
		padding: 0;
		p{
			padding: 0;
			color: #fff;
		}
	}
}
.section_form{
	padding: 77px 0 0;
	background-color: $site-color;
	.section_title{
		margin-bottom: 12px;
		color: #fff;
	}
	&_text{
		color: #fff;
		font-size: 18px;
		line-height: 28px;
	}
	&_phone_wrap{
		position: relative;
		width: 100%;
		height: 100%;
	}
	&_phone{
		margin-top: -133px;
		max-width: 100%;
		display: block !important;
		object-position: center;
		object-fit: contain;
	}
}
.section_gallery{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	flex-direction: row;
}
.section_docs{
	padding: 140px 0 145px;
	background-color: $site-color;
	.section_title{
		margin-bottom: 60px;
		color: #fff;
	}
}
.docs_wrap{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.doc{
	width: 200px;
	height: 288px;
	position: relative;
	margin-bottom: 30px;
	&:hover{
		&::after{
			opacity: .3;
		}
	}
	&::after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
		opacity: 0;
		transition: opacity .4s;
	}
	&_img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}
.gallery{
	&_item{
		display: block;
		height: 432px;
		flex: 1 0 33%;
		position: relative;
		&:hover{
			&::after{
				opacity: .3;
			}
		}
		&::after{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #fff;
			opacity: 0;
			transition: opacity .4s;
		}
	}
	&_img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}
.section_reviews{
	padding: 120px 0 120px;
	.section_title{
		margin-bottom: 60px;
	}
	.container{
		position: relative;
	}
}
.reviews_slider{
	position: relative;
	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-width: 88.5%;
		background: #FAFAFA;
		border-radius: 5px;
	}
	&_slide{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 57px 0 57px 100px;
		max-width: 88.5%;
		height: 516px;
	}
	&_info{
		max-width: 82.4%;
		&_top{
			margin-bottom: 39px;
		}
	}
	&_fio{
		margin: 0 0 10px;
		font-size: 20px;
		line-height: 1;
		color: $text-color;
		font-weight: 500;
	}
	&_text{
		font-family: Vremena Grotesk Book;
		font-size: 18px;
		line-height: 26px;
		color: $text-color;
	}
	&_dolzhnost{
		font-family: Vremena Grotesk Book;
		font-size: 15px;
		line-height: 1;
		color: #a4a4a4;
		margin-bottom: 25px;
	}
	&_doc{
		display: block;
		width: 270px;
		height: 402px;
		flex: 0 0 auto;
		margin-right: -135px;
		position: relative;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.08);
		&:hover{
			&::after{
				opacity: .3;
			}
		}
		&::after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			opacity: 0;
			background-color: #fff;
			transition-duration: .4s;
		}
	}
	&_controls{
		display: inline-flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 300px;
		bottom: 67px;
		.arrow{
			width: 68px;
			height: 68px;
			border-radius: 68px;
			cursor: pointer;
			border: 0;
			background-color: transparent;
			transition: background-color .4s;
			svg{
				width: 12px;
				height: 20px;
				fill: $site-color;
			}
			&:hover{
				background-color: #EAEAEA;
			}
			&.next{
				order: 1;
				svg{
					transform: rotate(-180deg);
				}
			}
			&.prev{
				
			}
		}
		.slick-dots{
			padding: 0 80px 2px;
			li{
				display: inline-block;
				width: 6px;
				height: 6px;
				margin: 0 5px;
				background-color: $site-color;
				border-radius: 10px;
				cursor: pointer;
				transition-duration: .4s;
				&.slick-active{
					transform: scale(1.2);
					background-color: $alt-color;
				}
			}
		}
	}
}
.footer{
	overflow: hidden;
	background-color: $site-color;
	.col-md-6>*{
		position: relative;
		z-index: 1;
	}
	.col-md-6:first-child{
		padding-right: 0;
		padding-top: 120px;
		padding-bottom: 190px;
	}
	.col-md-6:last-child{
		padding-left: 0;
	}
	.secton_title{
		margin-bottom: 39px;
		font-size: 36px;
		line-height: 1;
		color: #fff;
	}
	.top{
		position: relative;
	}
	.bottom{
		background-color: #252525;
		padding: 29px 0 27px;
		position: relative;
		z-index: 1;
		.text{
			color: #777;
			font-size: 16px;
			line-height: 1;
			display: block;
			svg{
				width: 120px;
				height: 24px;
				margin-left: 5px;
				fill: #777;
			}
			&.made_by{
				margin-top: -3px;
				float: right;
			}
			&[data-toggle]{
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
	.map{
		position: relative;
		z-index: 1;
		width: 50vw;
		height: 100%;
		.markers{
			display: none;
		}
	}
}
@import 'media';