.page_404{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100%;
}
.section_404{
	flex: 1 0 auto;
}
.body_404{
	height: 100%;
	padding: 110px 15px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $text-color;
	.section_title{
		margin-bottom: 22px;
		text-align: center;
	}
}
.text_404{
	max-width: 770px;
	margin: 0 0 40px;
	font-size: 16px;
	line-height: 1.5;
	text-align: center;
}
.number_404{
	font-size: 200px;
	line-height: 1;
	color: $text-color;
	text-align: center;
}
.button_404{
	width: 270px;
}
@media (max-width: 991px){
	.number_404{
		font-size: 150px;
	}
}